import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 1000 1000">
    <title>Logo</title>
    <g>
      <path
        d="M 440.01661,250 89.912838,750 205.91301,750.00022 338.95227,560 H 500 l -3e-5,190.00022 h 100.0001 L 600,560 h 133.03944 l 56.0166,-80 H 600 l 7e-5,-149.99999 h 294.08696 l 56.01673,-79.99977 z M 499.99997,330.00001 500,480 H 394.96888 Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

export default IconLogo;
