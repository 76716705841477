import React from 'react';

const IconPen = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    width="24"
    height="24"
    viewBox="0 0 512 512"
    fill="none"
    stroke="currentColor"
    strokeWidth="1"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="code">
    <path d="m498.828125 76.84375-63.671875-63.675781c-8.488281-8.484375-19.792969-13.16406275-31.828125-13.167969-.007813 0-.011719 0-.019531 0-12.035156 0-23.332032 4.671875-31.8125 13.152344l-324.648438 324.644531c-2.660156 2.722656-3.753906 6.597656-4.542968 9.023437l-41.726563 146.046876c-1.496094 5.242187-.035156 10.882812 3.816406 14.734374 2.855469 2.855469 6.691407 4.398438 10.613281 4.398438 1.378907 0 2.765626-.1875 4.125-.578125l145.964844-41.703125c.433594-.125 6.882813-2.34375 9.105469-4.566406l324.644531-324.648438c8.488282-8.484375 13.15625-19.789062 13.152344-31.832031-.003906-12.035156-4.683594-23.339844-13.171875-31.828125zm-461.964844 398.292969 28.023438-98.078125 70.054687 70.054687zm126.726563-41.824219-84.902344-84.902344 260.953125-260.953125 84.902344 84.902344zm314.03125-314.03125-31.851563 31.855469-84.90625-84.90625 31.855469-31.851563c2.8125-2.8125 6.574219-4.359375 10.589844-4.359375h.007812c4.023438 0 7.792969 1.554688 10.613282 4.375l63.675781 63.675781c5.851562 5.851563 5.859375 15.367188.015625 21.210938zm0 0" />
  </svg>
);

export default IconPen;
