import React from 'react';

const IconMedium = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="512"
    height="512"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth=".4"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="medium-logo">
    <path d="m.338 4.422 2.597 1.708v10.867l-2.712 2.676c-.476.471-.141 1.284.527 1.284h5.869c.667 0 1.004-.812.527-1.284l-2.712-2.676v-7.912c2.781 5.916 5.492 11.673 5.427 11.542.274.556 1.141.571 1.376-.075l.001-.002s.001-.001.001-.002c.65-1.793 2.044-5.638 1.955-5.394.05-.139 2.421-6.906 2.458-7.011v9.773l-1.741 1.765c-.467.474-.13 1.277.534 1.277h8.805c.662 0 1-.799.536-1.274l-1.673-1.713-.067-11.933 1.731-1.708c.476-.471.141-1.284-.527-1.284-.702 0 2.545-.001-6.87.004-.958.028-.634.889-3.986 9.772l-4.11-9.329c-.12-.272-.39-.448-.687-.448h-6.847c-.742.001-1.033.97-.412 1.377zm2.239 15.036 1.107-1.093 1.107 1.093zm14.575-14.912h4.271c-.479.535-.881.691-.879 1.185l.071 12.551c.002.476.391.643.854 1.175h-5.23c.517-.587.914-.748.914-1.234v-13.677zm-10.044 0 4.554 10.335-1.226 3.447c-6.176-13.116-5.959-13.322-7.181-13.782z"></path>
  </svg>
);

export default IconMedium;
